<template>
  <div class="flex mx-auto my-auto mt-10">
    <div class="w-full">
      <svg
        class="animate-spin mx-auto opacity-50"
        v-if="!error"
        style="width: 48px; height: 48px"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          style="opacity: 0.25"
          cx="12"
          cy="12"
          r="10"
          stroke="#7f7f7f"
          stroke-width="4"
        ></circle>
        <path
          style="opacity: 0.75"
          fill="#7f7f7f"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <div class="mt-5 text-grey3">
        {{ message }}
      </div>

      <div class="flex" v-if="error">
        <router-link :to="{ name: 'profile' }" class="mx-auto">
          <m-button
            class="self-end mb-3 mt-5"
            button-style="btn-tertiary"
            title="Back to profile settings"
            segmentName="Notion: back to profile settings"
            icon="⚙️"
          >
            Back to profile settings
          </m-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';
import { segmentTrackRaw } from '@/api/Segment';
import { connectSlackIntegration } from '@/api/Cherry';

export default {
  components: {
    MButton,
  },
  data() {
    return {
      message: 'Connecting to Slack...',
      error: false,
    };
  },
  mounted() {
    const code = this.$route.query.code;
    if (!code) {
      const errorQuery = this.$route.query.error;
      this.showError(errorQuery);
    } else {
      this.connectWithCode(code);
    }
  },
  methods: {
    connectWithCode(code) {
      const payload = {
        code: code,
        redirect_uri:
          process.env.VUE_APP_PUBLIC_ENDPOINT +
          process.env.VUE_APP_SLACK_REDIRECT_URI,
      };

      connectSlackIntegration(payload)
        .then((r) => {
          if (r?.team_name) {
            this.$store.commit('setSlackIntegration', r);
            this.$router.replace({
              name: 'slackselectdefault',
            });
          } else {
            this.showError(r?.error);
          }
        })
        .catch((e) => console.error(e));
    },
    showError: function (message) {
      if (message) {
        this.message = 'Error connecting to Slack: ' + message;
      } else {
        this.message = 'Invalid response from Slack';
      }
      this.error = true;
      segmentTrackRaw({
        event: 'Error',
        properties: { errorMsg: 'slack-connect', details: this.message },
      });
    },
  },
};
</script>
<style scoped>
body {
  margin: 0;
  height: 100%;
  background-color: #fafcff;
}
#loader .animate-spin {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
