<template>
  <div class="mx-auto my-auto mt-10">
    <div class="mt-5 text-grey2 text-center">
      Successfully connected to Notion Workspace
      <span class="font-bold">{{ notionDetails.workspace_name }}</span
      >.
    </div>

    <div class="flex mt-10 justify-center text-center text-grey2">
      <div>Notes will be saved in:</div>
      <m-select
        v-model="pageSelected"
        class="max-w-lg ml-4 text-grey1 font-medium"
        :options="pageOptions"
        :pending="savingChange"
        @input="saveId"
        :placeholder="placeholder"
      ></m-select>
    </div>
    <div class="flex mt-10">
      <router-link :to="{ name: 'profile' }" class="mx-auto">
        <m-button
          class="self-end"
          button-style="btn-primary"
          title="Save"
          segmentName="Notion: save page ID"
          icon="⚙️"
        >
          Back to profile settings
        </m-button>
      </router-link>
    </div>
    <div class="flex mt-5">
      <m-button
        class="mx-auto"
        @click="disconnectNotion"
        buttonStyle="btn-tertiary"
        segmentName="Disconnet Notion"
        :pending="notionButtonPending"
      >
        Remove Notion integration
      </m-button>
    </div>
    <div class="mt-5 text-alerterror text-center">{{ errorText }}</div>

    <div class="mt-10 text-grey2 text-center">
      <a-link
        href="https://meetric.notion.site/Notion-e0583dc756ab4bcb857fa10c1b298993"
        >Need help with Notion integration?</a-link
      >
    </div>
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';
import MSelect from '@/components/UI/MSelect';
import ALink from '@/components/UI/ALink.vue';
import {
  saveNotionDatabaseId,
  removeNotionIntegration,
  listNotionPages,
} from '@/api/Cherry';
export default {
  name: 'NotionSelectPage',
  components: {
    MButton,
    ALink,
    MSelect,
  },
  data() {
    return {
      pageSelected: '',
      buttonText: 'Save',
      errorText: '',
      notionButtonPending: false,
      pages: [],
      placeholder: 'loading',
      savingChange: true,
    };
  },
  computed: {
    notionDetails() {
      return this.$store.getters['notionDetails'];
    },
    savedPageId() {
      return this.notionDetails?.database_id;
    },
    pageOptions() {
      if (this.pages) {
        return this.pages
          .filter((opt) => opt.properties.title.title.length > 0)
          .map((p) => {
            return {
              label: p.properties.title.title[0].plain_text,
              value: p.id,
            };
          });
      }
      return [];
    },
  },
  mounted() {
    if (this.notionDetails.pages) {
      this.pages = this.notionDetails.pages;
      this.placeholder = 'Select page';
      this.initialiseSelection();
    } else {
      listNotionPages().then((r) => {
        this.pages = r;
        this.initialiseSelection();
      });
    }
  },
  methods: {
    initialiseSelection() {
      this.placeholder = 'Select page';
      if (this.savedPageId) {
        this.pageSelected = this.savedPageId;
      } else {
        // by default, set first page as selection
        if (this.pages.length > 0) {
          this.pageSelected = this.pages[0].id;
          this.saveId();
        }
      }
      this.savingChange = false;
    },
    saveId: function () {
      const payload = {
        id: this.pageSelected,
        label: this.pageOptions.find((p) => p.value === this.pageSelected)
          ?.label,
      };
      this.savingChange = true;
      saveNotionDatabaseId(payload)
        .then((r) => {
          this.savingChange = false;
          //this.$snack('Notion database ID saved');
          this.$store.commit('setNotionIntegration', r);
          window.meetric.track('notionIdSaved');
        })
        .catch((e) => {
          this.savingChange = false;
          this.errorText = `Error: ${e}`;
        });
    },
    disconnectNotion() {
      this.notionButtonPending = true;
      removeNotionIntegration().then(() => {
        this.$store.commit('setNotionIntegration', {});
        this.$router.push({
          name: 'profile',
        });
        this.notionButtonPending = false;
      });
    },
  },
};
</script>
<style scoped></style>
