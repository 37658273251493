<template>
  <div class="mx-auto my-auto mt-10">
    <div v-if="slackTeamName" class="mt-5 text-grey2 text-center">
      Connected to
      <span class="font-bold">{{ slackTeamName }}</span> team in Slack
    </div>

    <div class="flex mt-10 text-center text-grey2">
      Notes will be sent to:
      <m-select
        class="ml-4 text-grey1 font-medium"
        v-model="channelSelected"
        value=""
        :options="channelsOptions"
        :pending="savingChange"
        @input="saveDefaultChannel()"
        :placeholder="placeholder"
      ></m-select>
    </div>

    <div class="flex mt-10">
      <router-link :to="{ name: 'profile' }" class="mx-auto">
        <m-button
          class="self-end"
          button-style="btn-primary"
          title="Back to profile settings"
          segmentName="Slack: back to profile settings"
          icon="⚙️"
        >
          Back to profile settings
        </m-button>
      </router-link>
    </div>

    <div class="flex mt-5">
      <m-button
        class="mx-auto"
        @click="disconnectSlack"
        buttonStyle="btn-tertiary"
        segmentName="Disconnet Slack"
        :pending="disconnectButtonPending"
      >
        Remove Slack integration
      </m-button>
    </div>
    <div class="mt-10 text-grey2 text-center">
      <a-link
        href="https://meetric.notion.site/Slack-6759d926a6f24b5dbbd0da1fa1ab80eb"
        >Need help with Slack integration?</a-link
      >
    </div>
  </div>
</template>

<script>
import MSelect from '@/components/UI/MSelect';
import MButton from '@/components/UI/MButton';
import ALink from '@/components/UI/ALink.vue';
import {
  listChannelsSlackIntegration,
  setDefaultChannelSlackIntegration,
  removeSlackIntegration,
} from '@/api/Cherry';

export default {
  name: 'SlackDefaultChannel',
  components: {
    MSelect,
    ALink,
    MButton,
  },

  data() {
    return {
      channelSelected: '',
      savingChange: true,
      channels: [],
      placeholder: 'loading',
      disconnectButtonPending: false,
    };
  },
  computed: {
    slackTeamName() {
      return this.$store.getters['slackDetails']?.team_name || '';
    },
    slackDefaultChannel() {
      return this.$store.getters['slackDetails']?.default_channel;
    },
    slackDefaultChannelId() {
      return this.slackDefaultChannel?.id || '';
    },
    channelsOptions() {
      if (this.channels) {
        return this.channels.map((ch) => {
          return { label: '#' + ch.name, value: ch.id };
        });
      }

      return [];
    },
  },
  mounted() {
    listChannelsSlackIntegration().then((r) => {
      if (r?.ok && Array.isArray(r?.channels)) {
        this.channels = r.channels;
        this.placeholder = 'Please select';
        this.channelSelected = this.slackDefaultChannelId;
        this.savingChange = false;
      }
    });
  },
  methods: {
    saveDefaultChannel() {
      this.savingChange = true;
      let payload = {};
      for (const channel of this.channels) {
        if (channel.id == this.channelSelected) {
          payload = channel;
          break;
        }
      }
      setDefaultChannelSlackIntegration(payload)
        .then((r) => {
          if (r?.id) {
            this.$store.commit('setSlackIntegration', {
              connected: true,
              team_name: this.slackTeamName,
              default_channel: r,
            });
          }
          this.savingChange = false;
        })
        .catch((e) => {
          console.error(e);
          this.savingChange = false;
        });
    },
    disconnectSlack() {
      this.disconnectButtonPending = true;
      removeSlackIntegration()
        .then(() => {
          this.$store.commit('setSlackIntegration', false);
          this.disconnectButtonPending = false;
          this.$router.push({
            name: 'profile',
          });
        })
        .catch((e) => {
          console.error(e);
          this.disconnectButtonPending = false;
        });
    },
  },
};
</script>
<style scoped></style>
